























































import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import Address, { AddressType, AddressSelect } from "@/models/Address";
import AddressService from "@/services/AddressService";
import { getModule } from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import Client from "@/models/Client";
import StringTool from "@/services/tool/StringTool";
import LangModule from "@/store/LangModule";
import AddressDialog from "../dialogs/AddressDialog.vue";

@Component({components: { AddressDialog }})
export default class ClientAdressTab extends Vue {
  @Ref() readonly form!: HTMLFormElement
  @Prop() readonly client!: Client
  lang: any = getModule(LangModule).lang
  dialog: boolean = false
  detailDialog: boolean = false
  loading: boolean = false
  //@ts-ignore
  addressType: AddressType = AddressType
  address: Address = new Address()
  addresses: Address[] = []
  headers = [
    { text: "Id", value: "id", align: "center", width: "auto" },
    { text: this.lang.address, value: "address", align: "center", width: "auto" },
    { text: this.lang.action, value: "actions", align: "center", width: "auto" },
  ];
  minLength:number = 4
  maxLength:number = 250
  //validations for each form field
  basicRules = [
    (input: string) => (input ? true : this.lang.emptyLabel),
    (input: string) => input?.length >= this.minLength ? true : this.lang.minimumLength,
    (input: string) => input?.length <= this.maxLength ? true : this.lang.maximusLength,
  ];

  namesRules:((input: string) => string | true)[] = this.basicRules
  addressRules:((input: string) => string | true)[] = this.basicRules
  
  postalCodeRules = [
    (input: string) => StringTool.validatePostalCode(input) ? true : this.lang.invalidPostalCode,
  ]
  emailRules = [
    (input: string) => StringTool.validateEmail(input) ? true : this.lang.invalidEmail
  ]

  created() {
    this.refresh();
  }

  refresh() {
    AddressService.getAddressByClient(this, this.addresses, this.client.id!);
  }

  addressDetail(address: Address) {
    this.detailDialog = true;
    this.address = address;
  }

  closeDetail() {
    this.detailDialog = false;
    this.address = new Address();
  }

  deleteAddress(item: Address) {
    getModule(DialogModule).showDialog(
      new Dialog(this.lang.warning, this.lang.deleteAddressConfirmation, () =>
        AddressService.deleteAddress(this, item.id!)
      )
    );
  }

  createAddress() {
    if (this.form.validate()) {
      getModule(DialogModule).showDialog(
        new Dialog(this.lang.warning, this.lang.createAddressConfirmation, () =>
          AddressService.postAddress(this, this.address, this.client.id!)
        )
      );
    }
  }

	@Watch('client')
	onClientChanged() {
		this.refresh()
	}
}
