import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import {Tab} from "@/services/tool/TabTool";

let lang: any = getModule(LangModule).lang

export default class TabLists {

    static personalTab: Tab = {key: "personal", title: lang.myCompany, icon: "mdi-information-outline", image: "mi-empresa.png"}
    static logoutTab: Tab = {key: "logout", title: lang.logout, icon: "mdi-close", image: "cerrar.png"}

    static settingsOrderList: Tab[] = [
        {key: "orders", title: lang.orders, icon: "mdi-tag-multiple", image: "pedidos.png"},
        {key: "bills", title: lang.invoices, icon: "mdi-file-document-outline", image: "facturas.png"},
        // { index: 3 , key: "promotions", title: lang.promo, icon: "mdi-download", image: "mi-empresa.png"},
        // { index: 4 , key: "notifications", title: lang.notifications, icon: "mdi-message-reply-text-outline", image: "mi-empresa.png"},
        {key: "favourites", title: lang.favourites, icon: "mdi-star", image: "favoritos.png"},
        {key: "clients", title: lang.clients, icon: "mdi-account", image: "clientes.png"},
    ]

    static headquarterTabs: Tab[] = [
        {key: "headquarterOrders", title: "Pedidos de sedes", image: "pedidos.png", icon: "mdi-tag-multiple", divider: true, subheader: "Sedes"},
    ]



}