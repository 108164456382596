


















































import { Component, Prop, Ref, Vue } from "vue-property-decorator"
import Invoice from "@/models/Invoice"
import SessionModule from "@/store/SessionModule"
import { getModule } from "vuex-module-decorators"
import LangModule from "@/store/LangModule"

@Component
export default class BillDetailsDialog extends Vue {
  @Prop() readonly dialog!: boolean
  @Prop() readonly invoice!: Invoice
  lang: any = getModule(LangModule).lang
  
   headers = [
    { align: "center", width: "20%" ,text: this.lang.reference, value: "product.connectorId", class: "grey lighten-2" },
    { align: "center", width: "20%" ,text: this.lang.name, value: "product.name", class: "grey lighten-2" },
    { align: "center", width: "20%" ,text: this.lang.quantity, value: "quantity", class: "grey lighten-2" },
    { align: "center", width: "20%" ,text: "P. Tarifa", value: 'taxBase', class: "grey lighten-2"},
    { align: "center", width: "20%" ,text: "Total", value: 'total', class: "grey lighten-2"},
  ]

  headerso = [
    { text: this.lang.reference, value: "product.connectorId", width: "120px" },
    { text: this.lang.name, value: "product.name", width: "200px" },
    { text: this.lang.quantity, value: "quantity", width: "120px" },
    {text: this.lang.ratePrice, value: 'taxBase', width: "100px" },
    { text: this.lang.total, value: 'total' },
  ]

  sessionModule: SessionModule = getModule(SessionModule)

  get rateId() {
    return this.sessionModule.session.user.rateId
  }

  get total(): number {
    let total = 0
    const values = this.invoice.invoiceLines!.map(e => Number(e.product!.commercialRate) * Number(e.quantity!))
    values.forEach(e => {total = total + e})
    return total
  }

  get totalQuantity(): number {
    let total = 0
    const values = this.invoice.invoiceLines!.map(e => Number(e.quantity!))
    values.forEach(e => {total = total + e})
    return total
  }

}
