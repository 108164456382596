

















import LoginService from "@/services/LoginService"
import LangModule from "@/store/LangModule"
import {Component, Vue} from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"

@Component
export default class LogoutTab extends Vue {
  
  lang: any = getModule(LangModule).lang

  logout() {
    LoginService.logout(this)
  }

}
