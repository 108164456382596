import LangModule from "@/store/LangModule"
import {Component, Vue} from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"

let lang: any = getModule(LangModule).lang 
export default class TabTool {

  // static tabSwitch(value: string | number) {
  //   if (typeof value == 'number') { return this.tabs[value] }
  //   if (typeof value == 'string') { return this.tabs.find(e => e.key == 'personal') }
  // }
  
  // static setRouteByTab(tab: number, component: Vue) {
  //   //console.log(`/profile/settings/${this.tabSwitch(tab)?.name}`)
  //   component.$router.replace(`/profile/settings/${this.tabSwitch(tab)?.key}`).catch(() => {})
  // }

}

export class Tab {
  key?: string
  title?: string
  icon?: string
  image?: string
  divider?: boolean
  subheader?: string
}