































































import Invoice from "@/models/Invoice"
import InvoiceService from "@/services/InvoiceService"
import BillDetailsDialog from "@/components/dialogs/BillDetailsDialog.vue"

import {Component, Vue, Watch} from "vue-property-decorator"
import LangModule from "@/store/LangModule"
import { getModule } from "vuex-module-decorators"

@Component({ components: { BillDetailsDialog } })
export default class BillsTab extends Vue {

  invoices: Invoice[] = []
  selectedInvoice: Invoice = new Invoice()
  totalItems: number = 0
  itemsPerPage: number = 10
  page: number = 1
  pageCount: number = 0
  loading: boolean = false
  dialog: boolean = false
  lang: any = getModule(LangModule).lang
  
  headers = [
    { text: this.lang.invoiceNumberHeader, align: 'center', value: 'connectorId' },
    { text: this.lang.date, align: 'center', value: 'date'},
    { text: this.lang.total, align: 'center', value: 'total' },
    //{ text: 'Ver', align: 'center', value: 'ver'},
  ]

  created() {
    this.refresh()
  }

  refresh() {
    InvoiceService.getInvoices(this, this.invoices, this.page - 1, 10)
  }

  @Watch('loading')
	onLoadingChanged() {
		if (!this.loading) {
			this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage)
		}
  }

  @Watch('page')
	onPageChanged() {
		this.refresh()
  }

  rowClick(item: Invoice) {
    this.selectedInvoice = item
    this.dialog = true
  }
}
