














































































































import {Component, Prop, Ref, Vue} from "vue-property-decorator"
import Client from "@/models/Client"
import Address from "@/models/Address"
import {getModule} from "vuex-module-decorators"
import DialogModule from "@/store/DialogModule"
import Dialog from "@/models/vue/Dialog"
import ClientService from "@/services/ClientService"
import AddressService from "@/services/AddressService"
import StringTool from "@/services/tool/StringTool"
import SnackbarModule from "@/store/SnackbarModule"
import LangModule from "@/store/LangModule"

@Component
export default class extends Vue {
  client: Client = new Client()
  address: Address = new Address()
  step: any = 0
  valid: boolean = true
	loading: boolean = false
  length: number = 2
  lang: any = getModule(LangModule).lang
  @Ref() readonly personalForm!: HTMLFormElement
  @Ref() readonly addressForm!: HTMLFormElement
  @Prop() readonly dialog!: boolean
  @Prop() refresh!: any
  postalCodeRules = [
    (input: string) => StringTool.validatePostalCode(input) ? true : this.lang.invalidPostalCode,
  ]

  emailRules = [
    (v: string) => v && v.length > 0 ? true : this.lang.requiredEmail,
    (v: string) => StringTool.validateEmail(v) ? true : this.lang.invalidEmail
  ]

  basicRules = [
    (input: string | undefined) => (input != undefined ? true : this.lang.emptyLabel),
    (input: string  | undefined) => input != undefined && input.length >= 4 ? true : this.lang.minimumLength,
    (input: string  | undefined) => input != undefined && input.length <= 250 ? true : this.lang.minimumLength,
  ]

  titleRules = [(v: string) => v && v.length >= 2 ? true : this.lang.minimumLength]

  validateForm(form: HTMLFormElement) {
    let invalidInput: string = ""

    for (const input of form.inputs) {
      if (!input.valid) { invalidInput = input.label }
    }

    if (!form.validate()) {
      getModule(SnackbarModule).makeToast(`El campo de ${invalidInput} es invalido.`);
    } else {
      return true
    }
    return false
  }

  nextStep() {
    this.step++
    this.autofill()
  }

  autofill() {
    this.address.name = this.client.name
    this.address.email = this.client.email
    this.address.phone = this.client.phone
  }

  createClient() {
    getModule(DialogModule).showDialog(new Dialog(
      this.lang.warning,
      this.lang.createClientConfirmation,
      async () => {
        await ClientService.postClient(this, this.client, this.address)
        await AddressService.postAddress(this, this.address, this.client.id!).then(() => { this.$emit('closeDialog') })
        this.refresh()
      }
    ))
  }
}
